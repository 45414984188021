


















import { BuchListeModel } from "@/models/BuchListeModel";
import { catVue } from "@/util/logging";
import Vue from "vue";
export default Vue.extend({
   // Component Name
   name: "Buchlisten",

   components: {
      BuchListenListe: () =>
         import(
            /* webpackChunkName: "BuchListenListe" */ "@/components/bücher/BuchListen-Liste.vue"
         ),
   },

   created() {
      catVue.info(`${this.$options.name}:: CREATED... `);
   },
   mounted() {
      catVue.info(`${this.$options.name}:: MOUNTED... `);

      BuchListeModel.alle();
      // this.fetch();
   },
});
