import { BaseType } from "./util/BaseType";
import { Expose } from "class-transformer";
import { api } from '@/util/api';
import { catApi } from '@/util/logging';

export class BuchListeModel extends BaseType {

    @Expose({ name: "uid" }) uid = '';
    @Expose({ name: "name" }) name = '';
    @Expose({ name: "bestseller" }) bestseller = '';
    @Expose({ name: "bestand" }) bestand = '';
    @Expose({ name: "sortierung" }) sortierung = '';
    @Expose({ name: "url" }) url = '';

    public get isBestand(): boolean { return this.bestand == "Y" }
    public set isBestand(value: boolean) { this.bestand = value ? "Y" : "N" }
    public get isBestseller(): boolean { return this.bestseller == "Y" }
    public set isBestseller(value: boolean) { this.bestseller = value ? "Y" : "N" }
    public get isSortiert(): boolean { return this.sortierung == "Y" }
    public set isSortiert(value: boolean) { this.sortierung = value ? "Y" : "N" }

    public get pills(): string { return this.bestseller + this.bestand }

    // === === === === === === === === === === === === === === === === === === === === === === ===

    static mitVerkaufInZeitraum = async (von: string, bis: string, view: string): Promise<BuchListeModel[]> => {

        catApi.info("mitVerkaufInZeitraum: " + von + "..." + bis);

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await api.get<BuchListeModel[]>(
                "v1/statistiken/buchverkaeufe", {
                params: { 'von': von, 'bis': bis, 'view': view, 'nach': "liste" }
            }
            );
            catApi.debug("response: /v1/statistiken/buchverkaeufe -> " + JSON.stringify(response));

            return BaseType.convertArray(BuchListeModel, response.data)
        }
        catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }

    static alle = async (): Promise<BuchListeModel[]> => {

        catApi.info("alle: ");

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await api.get<BuchListeModel[]>(
                "v1/buchlisten/", {
                params: {}
            }
            );
            catApi.debug("response: /v1/buchlisten -> " + JSON.stringify(response));

            return BaseType.convertArray(BuchListeModel, response.data)
        }
        catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }
}


// === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === 

export class BuchListenEintragModel extends BaseType {

    @Expose({ name: "uid" }) uid = '';
    @Expose({ name: "url" }) url = '';
    @Expose({ name: "seq" }) seq = 0;

    @Expose({ name: "buch_uid" }) buid = '';
    @Expose({ name: "buch_url" }) burl = '';
    @Expose({ name: "name" }) name = '';

    // === === === === === === === === === === === === === === === === === === === === === === ===

    static byListe = async (url: string): Promise<BuchListenEintragModel[]> => {

        catApi.info("byListe: " + url);

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await api.get<BuchListenEintragModel[]>(
                "v1" + url);
            catApi.debug("response: /v1" + url + " -> " + JSON.stringify(response));

            return BaseType.convertArray(BuchListenEintragModel, response.data)
        }
        catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }

}
